import React from 'react'
import styled from 'styled-components'

import { Container, FooterDark } from './styles/elements'

const FooterContainer = styled(Container)`
  padding: 40px 0;
`

const Footer = () => (
  <FooterContainer>
    <FooterDark>
        Find me around the web on <a href="https://twitter.com/onishiweb">Twitter</a>, <a rel="me" href="https://mastodon.social/@onishiweb">Mastodon</a> and <a href="https://instagram.com/adamonishiphotography">Instagram</a>
    </FooterDark>
  </FooterContainer>
)

export default Footer
