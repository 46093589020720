import React from 'react'
import styled from 'styled-components'

import LayoutDark from '../components/layoutDark'
import Seo from '../components/seo'

import { media } from '../components/styles/variables'
import { ButtonDark, Input, Label, PageHeading, FooterDark } from '../components/styles/elements'
import { Wrapper } from '../components/styles/grid'

const MainColumn = styled.main`
    grid-column: 2 / span 13;

    ${media.greaterThan('small')`
        grid-column: 2 / span 10;
    `}
`

const MessageInput = styled.textarea.attrs({
    rows: 6,
})`
    display: block;
    width: 100%;
    margin: 0 0 20px;
    padding: .5em .25em;
    border: 0;
`

const SubmitButton = styled(ButtonDark).attrs({
    type: 'submit',
    value: 'Submit',
})`
    border: 0;
`


const ContactPage = () => (
    <LayoutDark>
        <Seo title='Contact' keywords={[`gatsby`, `application`, `react`, `blog`, `web developer`, `photographer`]} />

        <Wrapper>

            <PageHeading>Contact me</PageHeading>

            <MainColumn>

                <p>Want to speak to me about an exciting project, an upcoming conference, or a role you think I would be interested in (no recruiters please) drop me a message using the form below or email me at <a href='mailto:adam@onishi.ltd'>adam@onishi.ltd</a></p>

                <form name='Contact' method='POST' data-netlify='true' data-netlify-honeypot='bot-field'>
                    <Label htmlFor='contact-name'>Name:</Label>
                    <Input type='text' name='contact-name' id='contact-name' />
                    <Label htmlFor='contact-email'>Email:</Label>
                    <Input type='text' name='contact-email' id='contact-email' />
                    <Label htmlFor='contact-message'>Message:</Label>
                    <MessageInput id='contact-message' name='contact-message' />

                    <p style={{display:'none'}}>
                        <input name="bot-field" />
                    </p>

                    <input type="hidden" name="form-name" value="Contact" />

                    <SubmitButton as='input' />
                </form>
            </MainColumn>

        </Wrapper>
    </LayoutDark>
)

export default ContactPage
