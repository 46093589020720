import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'

import Header from './header'
import Footer from './footer'
import { GlobalStyle, Container } from './styles/elements'
import { Colors } from './styles/variables'
import './styles/normalise.css'
import logoLight from '../images/ao-logo-light.svg'

if (typeof window !== `undefined`) {
  const WebFont = require('webfontloader')

  WebFont.load({
    google: {
      families: ['Libre Baskerville:400,700', 'PT Sans:400,700']
    },
    classes: false
  })
}

const theme = {
  background: Colors.dark,
  color: Colors.light,
  hover: Colors.orangeDark,
  border: Colors.light,
  link: Colors.orangeDark,
  linkHover: Colors.orange,
  logo: logoLight,
  logoHover: Colors.light,
}

const Layout = ({ children }) => (
  <>
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle theme={theme} />

        <Header theme={theme} />

        <Container>
          {children}

          <Footer />
        </Container>
      </>
    </ThemeProvider>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
